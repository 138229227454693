<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="mt-2 ml-2">
      <b-row>
        <b-col cols="12">
          <b-skeleton v-if="isLoading" animation width="85%"></b-skeleton>
          <h2 v-else>{{ pg.name + ' - ' + pg.network.name}}</h2>
        </b-col>
      </b-row>
    </div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      :items="filterPg(teste)"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="isLoading? '': 'Nenhum membro encontrado'"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      stacked="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :foot-clone="isLoading"
    >
    <!-- Column: Id -->
      <template #cell(occupations)="data">
        <div v-for="o in data.item.occupations" :key="o.id">{{ o.type.description }}</div>
      </template>
      <template                                                                                                                                                                         
        #foot()="data"                                                                      
      >                                                                                                 
      <b-skeleton
      width="90%"
      height="15px"
  class="mb-0 mt-0"
></b-skeleton>                                                                                       
      </template>  
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted"
            >Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de
            {{ dataMeta.of }} membros</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPgs"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSkeleton,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import store from "@/store";

import pgStoreModule from "./pgsStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BSkeleton,
  },
  setup(props,context) {
    try {
      const INVOICE_APP_STORE_MODULE_NAME = "apppgs";
      const route = context.root.$route;

      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.registerModule(INVOICE_APP_STORE_MODULE_NAME, pgStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
          store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
      });

      const isLoading = computed(() => store.state.apppgs.loading);

      const statusOptions = [
        "Rede de Homens",
        "Rede de Mulheres",
        "Rede de Casais",
        "Rede de Jovens",
      ];
      const fetchMembros = () => {
        store.dispatch("apppgs/fetchMembros", route.params.id);
      };

      const fetchPg = () => {
        store.dispatch("apppgs/fetchPg", route.params.id);
      };

      fetchMembros();
      fetchPg();

      const teste = computed(() => store.state.apppgs.membros);
      const pg = computed(() => store.state.apppgs.pg);
      const totalPgs = ref(0);

      const dataMeta = computed(() => {
        return {
          from: perPage.value * currentPage.value + 1 - perPage.value,
          to:
            perPage.value * currentPage.value > totalPgs.value
              ? totalPgs.value
              : perPage.value * currentPage.value,
          of: totalPgs.value,
        };
      });

      const tableColumns = [
        { key: "people.id", label: "#", sortable: true },
        { key: "people.name", label: "Nome", sortable: true },
        { key: "occupations", label: "Funções", sortable: false },
      ];
      const perPage = ref(10);
      const currentPage = ref(1);
      const perPageOptions = [10, 25, 50, 100];
      const searchQuery = ref("");
      const sortBy = ref("");
      const isSortDirDesc = ref(true);
      const statusFilter = ref(null);

      function filterPg(pgs) {
        if (statusFilter.value != null) {
          pgs = pgs.filter((word) =>
            word.people.name.includes(statusFilter.value)
          );
        }

        // pgs = pgs.filter((word) => );
        // pgs = pgs.filter((word) => );
        pgs = pgs.filter(
          (word) =>
            word.people.name
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
        );

        totalPgs.value = pgs.length ?? 0;

        return pgs;
      }

      return {
        tableColumns,
        perPage,
        currentPage,
        totalPgs,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,

        statusFilter,
        statusOptions,

        avatarText,
        teste,
        pg,
        fetchMembros,
        filterPg,
        isLoading,
      };
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.pg-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
